
















































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";

import LineChart from "../chart/LineChart.vue";

import productSaleStoreModule from "@/store/modules/productSale";
import saleProductStoreModule from "@/store/modules/saleProduct";
import InventoryModule from "@/store/modules/productInventory";
import { Business, Role } from "@/types";
import { paddNumber } from "@/util/helpers";

const { mapActions: productActions, mapGetters: productGetters } =
  createNamespacedHelpers("SALE_PRODUCTS_");

const { mapActions: productSaleActions, mapGetters: productSaleGetters } =
  createNamespacedHelpers("PRODUCT_SALE_AGGREGATE_");

const {
  mapActions: prevProductSaleActions,
  mapGetters: prevProductSaleGetters,
} = createNamespacedHelpers("PREV_PRODUCT_SALE_AGGREGATE_");

const { mapGetters: InventoryGetters, mapActions: InventoryActions } =
  createNamespacedHelpers("InventoryModule");

export default Vue.extend<any, any, any, any>({
  components: { LineChart },
  name: "ProductSalesGraph",
  props: {
    periodOverride: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    period: "Day",
    days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    years: [
      2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033,
      2034, 2035,
    ],
    weeks: Array.from({ length: 52 }, (_, i) => paddNumber(i + 1)),
    productId: null,
    date: new Date().toISOString(),
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
      },
      tooltips: {
        titleFontSize: 12,
        bodyFontSize: 12,
        callbacks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          label(tooltipItem: any) {
            // Get the dataset label.
            // const label = data.datasets[tooltipItem.datasetIndex].label;

            // Format the y-axis value.
            const value = tooltipItem.yLabel;

            return `Ksh${value}`;
          },
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: (value: number) => {
                if (value / 1000 < 1) return value;
                return value ? value / 1000 + "k" : value;
              },
            },
            position: "right",
          },
        ],
      },
    },
    chartData: {
      labels: [] as string[] | number[],
      datasets: [
        {
          label: "Today",
          backgroundColor: "#FDF3E9",
          barPercentage: 0.5,
          data: [] as number[],
          pointRadius: 0,
          borderColor: "#F48203",
          pointStyle: "line",
        },
      ],
    },
  }),
  watch: {
    period: "getAggregate",
    productId: "getAggregate",
    role() {
      this.loadProductList();
    },
    periodOverride() {
      if (this.periodOverride) this.period = this.periodOverride;
    },
  },
  created() {
    this.fetchSaleProductList().then((saleProductPage) => {
      if (saleProductPage) {
        this.productId = saleProductPage.docs[0]?._id;
        this.getAggregate();
      }
    });
    this.loadProductList();
    this.getAggregate();
  },
  computed: {
    ...productSaleGetters(["productSaleAggregate"]),
    ...prevProductSaleGetters({
      prevProductSaleAggregate: "productSaleAggregate",
    }),
    ...productGetters(["saleProductPage"]),
    ...InventoryGetters(["productPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    vendor(): Business {
      return this.role.business as Business;
    },
    products(): any[] {
      return [{ _id: null, title: "All" }, ...this.productPage.docs];
    },
  },
  methods: {
    ...productSaleActions(["fetchProductSaleAggregate"]),
    ...prevProductSaleActions({
      fetchPrevProductSaleAggregate: "fetchProductSaleAggregate",
    }),
    ...productActions(["fetchSaleProductList"]),
    ...InventoryActions(["fetchProductList"]),
    loadProductList() {
      if (this.role) {
        const params = `?businessId=${(this.role.business as Business)?._id}`;
        this.fetchProductList(params);
      }
    },
    getAggregate() {
      const productId = this.productId;
      let params = "/aggregate";
      let prevPeriodParams = "/aggregate";
      let start: "?" | "&" = "?";
      const period = this.period.toLowerCase() as
        | "year"
        | "month"
        | "week"
        | "day"
        | "all";
      if (productId) {
        params += `?productId=${productId}`;
        prevPeriodParams += `?productId=${productId}`;
        start = "&";
      }
      const businessId = (this.role.business as Business)._id;

      if (period === "year" || period === "all") {
        params += `${start}period=year`;
        prevPeriodParams = params;
      } else if (period === "month") {
        const startDate = moment().startOf("year").toISOString();
        const endDate = moment().endOf("year").toISOString();
        params += `${start}startDate=${startDate}&endDate=${endDate}&period=${period}`;

        const prevStartDate = moment()
          .subtract(1, "year")
          .startOf("year")
          .toISOString();
        const prevEndDate = moment()
          .subtract(1, "year")
          .endOf("year")
          .toISOString();

        prevPeriodParams += `${start}startDate=${prevStartDate}&endDate=${prevEndDate}&period=${period}`;
      } else if (period === "week") {
        let startDate = moment().startOf("year").toISOString();
        let endDate = moment().endOf("year").toISOString();
        params += `${start}startDate=${startDate}&endDate=${endDate}&period=${period}`;

        startDate = moment().subtract(1, "year").startOf("year").toISOString();
        endDate = moment().subtract(1, "year").endOf("year").toISOString();
        prevPeriodParams += `${start}startDate=${startDate}&endDate=${endDate}&period=${period}`;
      } else if (period === "day") {
        const startDate = moment().startOf("week").toISOString();
        const endDate = moment().endOf("week").toISOString();
        params += `${start}startDate=${startDate}&endDate=${endDate}&period=${period}`;

        const prevStartDate = moment()
          .subtract(1, "week")
          .startOf("week")
          .toISOString();
        const prevEndDate = moment()
          .subtract(1, "week")
          .endOf("week")
          .toISOString();

        prevPeriodParams += `${start}startDate=${prevStartDate}&endDate=${prevEndDate}&period=${period}`;
      }
      if (params === "") params = `?businessId=${businessId}&period=${period}`;
      else params += `&businessId=${businessId}`;
      this.fetchProductSaleAggregate(params).then((aggregate) => {
        if (aggregate) {
          if (prevPeriodParams === "")
            prevPeriodParams = `?businessId=${businessId}&period=${period}`;
          else prevPeriodParams += `&businessId=${businessId}`;
          this.fetchPrevProductSaleAggregate(prevPeriodParams).then(
            (prevAggregate) => {
              if (prevAggregate) this.setChartData(period);
            }
          );
        }
      });
    },
    setChartData(period: string) {
      if (period === "day") {
        const dayData = {
          Sun: 0,
          Mon: 0,
          Tue: 0,
          Wed: 0,
          Thu: 0,
          Fri: 0,
          Sat: 0,
        };

        this.productSaleAggregate.map((a: { _id: number; amount: number }) => {
          const indx = this.days[a._id - 1] as "Sun" | "Mon";
          dayData[indx] = a.amount;
        });

        const prevPeriodData = {
          Sun: 0,
          Mon: 0,
          Tue: 0,
          Wed: 0,
          Thu: 0,
          Fri: 0,
          Sat: 0,
        };

        this.prevProductSaleAggregate.map(
          (a: { _id: number; amount: number }) => {
            const indx = this.days[a._id - 1] as "Sun" | "Mon";
            prevPeriodData[indx] = a.amount;
          }
        );

        this.chartData = {
          labels: this.days,
          datasets: [
            {
              label: "Today",
              backgroundColor: "#FDF3E9",
              data: Object.values(dayData),
              pointRadius: 0,
              borderColor: "#F48203",
              pointStyle: "line",
            },
            {
              label: "Yesterday",
              backgroundColor: "#FCFCFE",
              data: Object.values(prevPeriodData),
              pointRadius: 0,
              borderColor: "#DFE0EA",
              pointStyle: "line",
            },
          ],
        };
        return;
      }

      if (period === "week") {
        const data = {};

        const prevPeriodData = {};

        this.weeks.map((w) => {
          data[w] = 0;
        });
        this.productSaleAggregate.map((a: { _id: number; amount: number }) => {
          data[a._id] = a.amount;
        });

        this.prevProductSaleAggregate.map(
          (a: { _id: number; amount: number }) => {
            prevPeriodData[a._id] = a.amount;
          }
        );

        this.chartData = {
          labels: this.weeks,
          datasets: [
            {
              label: "Current Month",
              backgroundColor: "#FDF3E9",
              data: Object.values(data),
              pointRadius: 0,
              borderColor: "#F48203",
              pointStyle: "line",
            },
            {
              label: "Previous Month",
              backgroundColor: "#FCFCFE",
              data: Object.values(prevPeriodData),
              pointRadius: 0,
              borderColor: "#DFE0EA",
              pointStyle: "line",
            },
          ],
        };
        return;
      }

      if (period === "month") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data = {
          Jan: 0,
          Feb: 0,
          Mar: 0,
          Apr: 0,
          May: 0,
          Jun: 0,
          Jul: 0,
          Aug: 0,
          Sep: 0,
          Oct: 0,
          Nov: 0,
          Dec: 0,
        };

        this.productSaleAggregate.map((a: { _id: number; amount: number }) => {
          const indx = this.months[+a._id - 1] as "Jan" | "Feb";
          data[indx] = a.amount;
        });

        const prevPeriodData = {
          Jan: 0,
          Feb: 0,
          Mar: 0,
          Apr: 0,
          May: 0,
          Jun: 0,
          Jul: 0,
          Aug: 0,
          Sep: 0,
          Oct: 0,
          Nov: 0,
          Dec: 0,
        };

        this.prevProductSaleAggregate.map(
          (a: { _id: number; amount: number }) => {
            const indx = this.months[+a._id - 1] as "Jan" | "Feb";
            prevPeriodData[indx] = a.amount;
          }
        );

        this.chartData = {
          labels: Object.keys(data),
          datasets: [
            {
              label: "Current Year",
              backgroundColor: "#FDF3E9",
              data: Object.values(data),
              pointRadius: 0,
              borderColor: "#F48203",
              pointStyle: "line",
            },
            {
              label: "Previous Year",
              backgroundColor: "#FCFCFE",
              data: Object.values(prevPeriodData),
              pointRadius: 0,
              borderColor: "#DFE0EA",
              pointStyle: "line",
            },
          ],
        };
        return;
      }

      if (period === "year" || period == "all") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = {};

        const labels = this.years.filter((y) => new Date().getFullYear() >= y);

        labels.map((l) => {
          data[l] = 0;
        });

        this.productSaleAggregate.map((a: { _id: string; amount: number }) => {
          data[a._id] = a.amount;
        });

        this.chartData = {
          labels: labels,
          datasets: [
            {
              label: "Over the years",
              backgroundColor: "#FDF3E9",
              barPercentage: 0.5,
              data: Object.values(data),
              pointRadius: 0,
              borderColor: "#F48203",
              pointStyle: "line",
            },
          ],
        };
        return;
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PRODUCT_SALE_AGGREGATE_")) {
      this.$store.registerModule(
        "PRODUCT_SALE_AGGREGATE_",
        productSaleStoreModule
      );
    }
    if (!this.$store.hasModule("SALE_PRODUCTS_")) {
      this.$store.registerModule("SALE_PRODUCTS_", saleProductStoreModule);
    }

    if (!this.$store.hasModule("InventoryModule")) {
      this.$store.registerModule("InventoryModule", InventoryModule);
    }
    if (!this.$store.hasModule("PREV_PRODUCT_SALE_AGGREGATE_")) {
      this.$store.registerModule(
        "PREV_PRODUCT_SALE_AGGREGATE_",
        productSaleStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PRODUCT_SALE_AGGREGATE_");
    this.$store.unregisterModule("SALE_PRODUCTS_");
    this.$store.unregisterModule("InventoryModule");
    this.$store.unregisterModule("PREV_PRODUCT_SALE_AGGREGATE_");
  },
});
