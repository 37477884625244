import { Module } from "vuex";

import { api } from "@/util/axios";

type RevenueState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  revenueAggregate: any[];
  revenueReport: any[];
};

const revenue: Module<RevenueState, unknown> = {
  namespaced: true,
  state: () => ({
    revenueAggregate: [],
    revenueReport: [],
  }),
  getters: {
    revenueAggregate: (state) => state.revenueAggregate,
    revenueReport: (state) => state.revenueReport,
  },
  mutations: {
    SET_REVENUE_AGGREGATE: (state, list) => {
      state.revenueAggregate = list;
    },
    SET_REVENUE_REPORT: (state, list) => {
      state.revenueReport = list;
    },
  },
  actions: {
    fetchRevenueAggregate(context, params = "") {
      api
        .get(`/v1/revenue${params}`)
        .then((response) => {
          context.commit(
            "SET_REVENUE_AGGREGATE",
            response.data.revenueAggregate
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchRevenueReport(context, params = "") {
      api
        .get(`/v1/sales-ledger/sales-report${params}`)
        .then((response) => {
          context.commit("SET_REVENUE_REPORT", response.data.reports);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default revenue;
